var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"module-join-us shopee-promote"},[_c('div',{staticClass:"adaptive-width shopee-promote-inner"},[_vm._m(3),_c('div',{staticClass:"module-item-2 item-2"},_vm._l((_vm.configurationArr),function(item,index){return _c('div',{key:index,staticClass:"module-item-2-unit"},[_c('div',{staticStyle:{"margin-top":"23px","color":"#4285F7","font-size":"18px"}},[_vm._v(_vm._s(item.title))]),_c('div',{staticStyle:{"margin-top":"6px","line-height":"1.8"}},[_vm._v(_vm._s(item.content))])])}),0)])]),_c('div',{staticClass:"module-join-us shopee-life adaptive-width"},[_vm._m(4),_c('div',{staticClass:"module-item-2 item-2"},_vm._l((_vm.activityArr),function(item,index){return _c('div',{key:index,staticClass:"module-item-2-unit"},[_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"real-img",rawName:"v-real-img",value:(item.images),expression:"item.images"}],staticStyle:{"width":"440px","height":"275px"},attrs:{"alt":""}})]),_c('div',[_vm._v(_vm._s(item.title))]),_c('div',[_vm._v(_vm._s(item.content))])])}),0),_c('div',{staticClass:"slider-container"})]),_c('div',{staticClass:"module-join-us shopee-life adaptive-width"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"slider-container"}),_c('div',{staticClass:"module-join-us shopee-care-apart"},[_c('div',{staticClass:"module-item-2"},_vm._l((_vm.welfareArr),function(item,index){return _c('div',{key:index,staticClass:"welfare-item"},[_c('div',{staticClass:"point"},[_vm._v(_vm._s(item.title))]),_c('div',[_vm._v(_vm._s(item.content))])])}),0)])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-bg-wrap-join"},[_c('div',{staticClass:"header-bg-inner adaptive-width"},[_c('div',{staticClass:"page-title"},[_vm._v("加入Shopee")]),_c('button',{staticClass:"sc-light-btn jump-to-job-btn"},[_vm._v("查看所有职位")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopee-leader"},[_c('div',{staticClass:"shopee-leader-inner adaptive-width"},[_c('div',{staticClass:"shopee-leader-content"},[_c('p',{staticClass:"first"},[_vm._v("了解人才是成功领导的关键")]),_c('p',{staticClass:"second"},[_vm._v("在 Shopee，我们认为了解如何提供最佳体验予我们的职员、合作伙伴和用户至关重要。")])]),_c('div',{staticClass:"shopee-leader-author"}),_c('div',{staticClass:"shopee-leader-m-author"},[_c('img',{attrs:{"src":"https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/m-leader-name.cb38504.png","alt":""}}),_c('p',[_vm._v("首席执行官")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-join-us shopee-work adaptive-width"},[_c('div',[_c('div',{staticClass:"common-decorate-title"},[_c('span',{staticClass:"common-decorate-word"},[_vm._v("工作在xxx")])]),_c('p',{staticClass:"module-item-1-content"},[_vm._v("和我们一起创造跨境电商的未来。")]),_c('p',{staticClass:"m-module-item-1-content"},[_vm._v("和我们一起创造跨境电商的未来。")])]),_c('div',{staticClass:"no-place"},[_c('div',{staticClass:"no-place"},[_c('p',[_vm._v("xxx 跨境团队致力于帮助中国品牌和卖家成功出海东南亚和拉美九大市场。")]),_c('p',[_vm._v("我们为卖家提供一站式跨境解决方案，如跨境物流 SLS、小语种客服、运营孵化、支持保障、海外仓储和商业智能等服务。")]),_c('p',[_vm._v("我们通过Shopee跨境团队每一位员工的共同努力，让更多的中国好货和好商家在东南亚“蓝海”扬帆远航。")])]),_c('img',{staticClass:"img-span",attrs:{"src":"https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/1@2x.5b3314e.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"common-decorate-title"},[_c('span',{staticClass:"common-decorate-word"},[_vm._v("成长在xxx")])]),_c('p',{staticClass:"module-item-1-content"},[_vm._v(" 我们关注员工成长， "),_c('br'),_vm._v(" 搭建全方位学习发展体系， "),_c('br'),_vm._v(" 帮助员工面对未来。 ")]),_c('p',{staticClass:"m-module-item-1-content"},[_vm._v("我们关注员工成长，搭建全方位学习发展体系，帮助员工面对未来。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-item-1 item-1"},[_c('div',{staticClass:"common-decorate-title"},[_c('span',{staticClass:"common-decorate-word"},[_vm._v("生活在xxx")])]),_c('p',[_vm._v(" 高效工作，乐享生活。 "),_c('br'),_vm._v(" 我们倡导快乐工作文化， "),_c('br'),_vm._v(" 打造活力办公环境。 "),_c('br')]),_c('p',[_vm._v("高效工作，乐享生活。我们倡导快乐工作文化，打造活力办公环境。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-item-1 item-1"},[_c('div',{staticClass:"common-decorate-title"},[_c('span',{staticClass:"common-decorate-word"},[_vm._v("关怀在xxx")])]),_c('p',{staticClass:"module-item-1-content"},[_vm._v(" 我们设计了慷慨走心的福利关怀， "),_c('br'),_vm._v(" 为每位员工提供后顾无忧的可靠保障。 ")]),_c('p',{staticClass:"m-module-item-1-content"},[_vm._v("我们设计了慷慨走心的福利关怀，为每位员工提供后顾无忧的可靠保障。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-join-us shopee-care-apart"},[_c('div',{staticClass:"module-item-2"},[_c('img',{staticStyle:{"width":"912px","height":"320px"},attrs:{"src":"https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/6@2x.3912856.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-module slider-banner"},[_c('button',{staticClass:"sc-primary-btn jump-to-job-btn"},[_vm._v("查看所以职位")])])
}]

export { render, staticRenderFns }