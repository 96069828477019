<template>
  <div>
    <div class="header-bg-wrap-join">
      <div class="header-bg-inner adaptive-width">
        <div class="page-title">加入Shopee</div>
        <button class="sc-light-btn jump-to-job-btn">查看所有职位</button>
      </div>
    </div>
    <div class="shopee-leader">
      <div class="shopee-leader-inner adaptive-width">
        <div class="shopee-leader-content">
          <p class="first">了解人才是成功领导的关键</p>
          <p class="second">在 Shopee，我们认为了解如何提供最佳体验予我们的职员、合作伙伴和用户至关重要。</p>
        </div>
        <div class="shopee-leader-author"></div>
        <div class="shopee-leader-m-author">
          <img src="https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/m-leader-name.cb38504.png"
               alt="">
          <p>首席执行官</p>
        </div>
      </div>
    </div>
    <div class="module-join-us shopee-work adaptive-width">
      <div>
        <div class="common-decorate-title">
          <span class="common-decorate-word">工作在xxx</span>
        </div>
        <p class="module-item-1-content">和我们一起创造跨境电商的未来。</p>
        <p class="m-module-item-1-content">和我们一起创造跨境电商的未来。</p>
      </div>
      <div class="no-place">
        <div class="no-place">
          <p>xxx 跨境团队致力于帮助中国品牌和卖家成功出海东南亚和拉美九大市场。</p>
          <p>我们为卖家提供一站式跨境解决方案，如跨境物流 SLS、小语种客服、运营孵化、支持保障、海外仓储和商业智能等服务。</p>
          <p>我们通过Shopee跨境团队每一位员工的共同努力，让更多的中国好货和好商家在东南亚“蓝海”扬帆远航。</p>
        </div>
        <img src="https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/1@2x.5b3314e.png" class="img-span"
             alt="">
      </div>
    </div>
    <div class="module-join-us shopee-promote">
      <div class="adaptive-width shopee-promote-inner">
        <div>
          <div class="common-decorate-title">
            <span class="common-decorate-word">成长在xxx</span>
          </div>
          <p class="module-item-1-content">
            我们关注员工成长，
            <br>
            搭建全方位学习发展体系，
            <br>
            帮助员工面对未来。
          </p>
          <p class="m-module-item-1-content">我们关注员工成长，搭建全方位学习发展体系，帮助员工面对未来。</p>
        </div>
        <div class="module-item-2 item-2">
          <div class="module-item-2-unit" v-for="(item, index) in configurationArr" :key="index">
            <div style="margin-top: 23px; color: #4285F7; font-size: 18px;">{{ item.title }}</div>
            <div style="margin-top: 6px; line-height: 1.8;">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-join-us shopee-life adaptive-width">
      <div class="module-item-1 item-1">
        <div class="common-decorate-title">
          <span class="common-decorate-word">生活在xxx</span>
        </div>
        <p>
          高效工作，乐享生活。
          <br>
          我们倡导快乐工作文化，
          <br>
          打造活力办公环境。
          <br>
        </p>
        <p>高效工作，乐享生活。我们倡导快乐工作文化，打造活力办公环境。</p>
      </div>
      <div class="module-item-2 item-2">
        <div class="module-item-2-unit" v-for="(item, index) in activityArr" :key="index">
          <div class="img">
            <img style="width: 440px; height: 275px;" v-real-img="item.images" alt="">
          </div>
          <div>{{ item.title }}</div>
          <div>{{ item.content }}</div>
        </div>
      </div>
      <div class="slider-container"></div>
    </div>
    <div class="module-join-us shopee-life adaptive-width">
      <div class="module-item-1 item-1">
        <div class="common-decorate-title">
          <span class="common-decorate-word">关怀在xxx</span>
        </div>
        <p class="module-item-1-content">
          我们设计了慷慨走心的福利关怀，
          <br>
          为每位员工提供后顾无忧的可靠保障。
        </p>
        <p class="m-module-item-1-content">我们设计了慷慨走心的福利关怀，为每位员工提供后顾无忧的可靠保障。</p>
      </div>
      <div class="module-join-us shopee-care-apart">
        <div class="module-item-2">
          <img style="width: 912px; height: 320px;"
               src="https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/6@2x.3912856.png" alt=""/>
        </div>
      </div>
      <div class="slider-container"></div>
      <div class="module-join-us shopee-care-apart">
        <div class="module-item-2">
          <div class="welfare-item" v-for="(item, index) in welfareArr" :key="index">
            <div class="point">{{ item.title }}</div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-module slider-banner">
      <button class="sc-primary-btn jump-to-job-btn">查看所以职位</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configurationArr: [
        {title: 'xxx Academy', content: '完善的培训与发展体系，提供丰富的技术与通识类课程，成长为更好的你'},
        {title: '新员工培养计划', content: '提供不同周期的培训课程，以及专业导师一对一辅导、系统学习清单，帮助新员工快速胜任新岗位'},
        {title: '领导力发展项目', content: '针对不同层级需求设计，提升领导力及团队影响力，无缝衔接切换跑道，为蜕变成长加速'},
        {title: '校招生培养计划', content: '专为应届生打造，帮助更好地融入新环境，快速完成从学生到职场人的角色转化'},
        {title: '内训师&乐享会舞台', content: 'Keep Learning，Keep Training，我们为员工创造乐学、乐享舞台。越来越多的行业大咖加入内训师队伍，在乐享会舞台大放异彩'}
      ],
      activityArr: [
        {
          images: 'https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/life-2.88752f5.jpg',
          title: 'Orange day',
          content: '每月末周五定期举办的Shopee特色活动，每期不同主题.'
        },
        {
          images: 'https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/life-3.e0e7193.jpg',
          title: '兴趣协会',
          content: '我们崇尚多元，篮球、足球、羽毛球、游泳、乒乓球……各种兴趣协会任你加入！'
        },
        {
          images: 'https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/life-4.b93b2d1.jpg',
          title: '趣味团建',
          content: '季度团建、年度Outing，小伙伴们的脑洞有多大，我们就敢玩多High！'
        },
        {
          images: 'https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/life-5.3e53d1e.jpg',
          title: '庆典活动',
          content: '9.9大促、12.12庆典、周年庆……形式多样的集体活动让我们彼此更加紧密。'
        }
      ],
      welfareArr: [
        {title: '', content: '全年15天年假，14天全薪病假'},
        {title: '', content: '生日、节庆、周年礼物'},
        {title: '', content: '年度体检'},
        {title: '', content: '外出学习补助'},
        {title: '', content: '高级别商业保险'},
        {title: '', content: '电话补贴'},
        {title: '', content: '丰富的零食、饮料、水果'},
        {title: '', content: '加班餐供应'},
        {title: '', content: 'Super Monkey健身补贴'},
        {title: '', content: '......'}
      ]
    }
  }
}
</script>
